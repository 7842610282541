import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUSPs({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentUSPs" className={clsx('bg-rc-sandaconda', fields.spaceTop ? 'section' : 'section--bottom')}>
      <div className="container">
        {fields.description && <Content className="content text-white mb-12 lg:mb-20">{fields?.description}</Content>}

        {fields.links && (
          <div className="grid grid-cols-10 gap-4 lg:px-20">
            {fields.links.map((link, index) => (
              <LosseLink
                className={clsx(
                  index % 2 === 0 ? 'md:mt-44' : 'max-sm:mt-28 max-md:mt-32',
                  'col-span-5 md:col-span-2 group hover:-translate-y-3 focus:scale-95 transition-all'
                )}
                key={index}
                to={link?.link?.url || '/'}
                target={link?.link?.target || ''}
              >
                <LossePlaatjie
                  src={link?.icon}
                  className="group-hover:svg--white w-full object-contain mx-auto max-w-[150px] md:max-w-[175px] lg:max-w-[225px]"
                  maxwidth={300}
                />
                <h3 className="text-white mt-4 text-center md:text-lg lg:text-xl">{link?.link?.title}</h3>
              </LosseLink>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
