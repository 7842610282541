/* eslint-disable react-hooks/exhaustive-deps */
import { useLosseLayout, type LoaderData, useNavigation, LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import type { CommerceLoaderData } from '~/services/session.server'
import Cart from '~/components/elements/Cart'
import { useEffect, useRef } from 'react'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { useLayout } from '../Layout'
import clsx from 'clsx'
import type { GooglePlace } from '~/templates/page'

export default function HeaderDesktop({ setMenuOpen }: { setMenuOpen: (open: boolean) => void }) {
  const { cart, place } = useLoaderData<LoaderData & CommerceLoaderData & { place: GooglePlace }>()
  const { isSticky } = useLosseLayout()
  const layout = useLayout()
  const itemCountControls = useAnimation()
  const navigation = useNavigation()
  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      return
    }

    itemCountControls.start({ scale: 1.75, backgroundColor: '#555E26', transition: { duration: 0.2 } }).then(() => {
      itemCountControls.start({ scale: 1, backgroundColor: '#370709', transition: { duration: 0 } })
    })
  }, [cart.contents.itemCount, itemCountControls])

  useEffect(() => {
    if (navigation.state !== 'idle') {
      return
    }

    layout.setIsCartOpen(false)
  }, [navigation])

  return (
    <div className="flex flex-col relative items-end mt-3">
      <motion.span
        animate={!isSticky ? 'show' : 'hide'}
        variants={{
          show: {
            opacity: 1,
            y: 0
          },
          hide: {
            opacity: 0,
            y: -50
          }
        }}
        className="text-white text-sm font-rc-primary mb-2 pr-6 md:pr-12"
      >
        {place && place.isOpen !== undefined && (
          <>
            <span className="max-sm:hidden">Vandaag o</span>
            <span className="sm:hidden">O</span>pen van {place.openingsToday} uur
          </>
        )}

        {/* {!place?.isOpen && <span>Wij zijn gesloten</span>} */}
      </motion.span>
      <div
        className={clsx(
          layout.typeHeader === 'default' && 'bg-white shadow--box text-rc-sandaconda',
          layout.typeHeader === 'transparent' && 'text-white',
          isSticky && '-mt-5',
          'rounded-full py-3 sm:py-4 px-6 sm:px-8 flex items-center gap-x-4 transition-all'
        )}
      >
        <ul className="mb-0 hidden w-full list-none items-center justify-end lg:flex gap-x-2 xl:mr-16">
          <HeaderMenu />
        </ul>
        <LosseLink className="btn flex-none btn--light max-sm:hidden" to="/boulangerie/">
          <span className="max-xl:hidden">Online broodjes bestellen</span>
          <span className="xl:hidden">Broodjes bestellen</span>
        </LosseLink>

        <button
          type="button"
          onClick={() => {
            layout.setIsCartOpen(!layout.isCartOpen)
          }}
          className={clsx(layout.isCartOpen && 'pointer-events-none', 'relative btn--base btn--pseudo p-2 hover:bg-rc-quilladin group')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            className={clsx(
              layout.typeHeader === 'default' && 'text-rc-sandaconda group-hover:text-white',
              layout.typeHeader === 'transparent' && 'text-white'
            )}
          >
            <g fill="currentColor" clipPath="url(#clip0_1329_2587)">
              <path d="M24 3H4.242L4.2 2.649A3 3 0 001.222 0H0v2h1.222a1 1 0 01.993.883L3.8 16.351A3 3 0 006.778 19H20v-2H6.778a1 1 0 01-.993-.884L5.654 15h16.182L24 3zm-3.836 10H5.419l-.941-8h17.129l-1.443 8zM7 24a2 2 0 100-4 2 2 0 000 4zM17 24a2 2 0 100-4 2 2 0 000 4z"></path>
            </g>
          </svg>
          <motion.span
            className="p-1 px-[5.5px] bg-rc-quilladin rounded-full flex items-center justify-center text-white absolute -right-[2px] -top-[2px] text-[10px] leading-none"
            animate={itemCountControls}
          >
            {cart.contents.itemCount}
          </motion.span>
        </button>

        <button
          type="button"
          onClick={() => {
            setMenuOpen(true)
          }}
          title="Open menu"
          className="btn--pseudo group flex h-[40px] w-[40px] flex-col items-center rounded-full border-transparent lg:hidden"
        >
          <div className="z-10 h-5 w-5 text-center">
            <div
              className={clsx(
                layout.typeHeader === 'transparent' && 'bg-white',
                layout.typeHeader === 'default' && 'bg-rc-sandaconda',
                'mx-auto my-[3px] h-[2px] w-full'
              )}
            />
            <div
              className={clsx(
                layout.typeHeader === 'transparent' && 'bg-white',
                layout.typeHeader === 'default' && 'bg-rc-sandaconda',
                'mx-auto my-[3px] h-[2px] w-full'
              )}
            />
            <div
              className={clsx(
                layout.typeHeader === 'transparent' && 'bg-white',
                layout.typeHeader === 'default' && 'bg-rc-sandaconda',
                'mx-auto my-[3px] h-[2px] w-full'
              )}
            />
          </div>
          <div className="mt-1 text-sm text-rc-bg-rc-sandaconda">Menu</div>
        </button>
      </div>

      <AnimatePresence>
        {layout.isCartOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1.0 }}
            className="max-md:flex-center max-md:py-7 max-md:left-0 max-md:top-0 max-md:w-full max-md:h-full max-md:bg-black/50 fixed md:absolute right-0 md:top-28 z-50 md:rounded-2xl md:shadow--box bg-rc-litten md:max-w-sm md:w-full md:overflow-hidden max-h-[calc(100vh-150px)] lg:overflow-auto"
          >
            <div className="max-md:rounded-2xl max-md:max-h-full max-md:shadow--box max-md:bg-rc-litten max-md:overflow-auto max-md:max-w-lg">
              <Cart onClose={() => layout.setIsCartOpen(false)} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
