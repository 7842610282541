import clsx from 'clsx'

interface InputProps {
  name: string
  type: string
  label: string
  description?: string
  required?: boolean
  className?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  defaultValue?: string
}
export default function Input(props: InputProps) {
  return (
    <label className={clsx('flex flex-col', props.className)}>
      {/* <span className="mb-1">
        {props.label} {props.required && <span className="text-aj-orbeetle">*</span>}
      </span> */}

      <input
        className="form-duuf-input text-rc-quilladin !rounded-full px-4 py-2"
        placeholder={props.label}
        required={props.required}
        type={props.type}
        name={props.name}
        defaultValue={props.defaultValue}
      />
      <em className="text-sm mt-[2px]">{props.description}</em>
    </label>
  )
}
