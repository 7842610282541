/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { LosseLink, useLoaderData, useLocation } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import type { Acf_Link, Component_Header_MenuHeader_Submenu } from '~/graphql/types'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const location = useLocation()
  const [isOpen, setOpen] = useState(-1)

  useEffect(() => {
    if (isOpen !== -1) {
      setOpen(-1)
    }
  }, [location.pathname, location.hash, location.search])

  return (
    <>
      {header?.menuHeader?.map((menu, index) => (
        <motion.li
          onHoverStart={() => {
            if (menu?.hasSubmenu) setOpen(index)
          }}
          onHoverEnd={() => {
            if (menu?.hasSubmenu) setOpen(-1)
          }}
          className="relative "
          key={index}
        >
          <div className="flex flex-wrap items-center">
            <LosseLink
              className="btn--base btn--pseudo text-2xl lg:text-sm hover:bg-rc-quilladin hover:text-white py-2 px-3 xl:px-4 flex items-center"
              to={menu?.link?.url}
              target={menu?.link?.target || ''}
            >
              <div className="active-dot hidden w-2 h-2 rounded-full bg-rc-shedinja mr-2" />
              {menu?.link?.title}
            </LosseLink>
          </div>

          {menu?.hasSubmenu && !!menu.submenu && isOpen === index && (
            <AnimatePresence>
              <HeaderSubMenu link={menu.link as Acf_Link} fields={menu.submenu as Component_Header_MenuHeader_Submenu} />
            </AnimatePresence>
          )}
        </motion.li>
      ))}
    </>
  )
}
