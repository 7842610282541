import type { Page_Flexcontent_Flex_Posts, Product } from '~/graphql/types'
import Slider, { Slide } from '~/components/elements/Slider'
import ProductComponent from '../ProductsWithFilters/Product'
import Content from '~/components/elements/Content'
import { LosseLink } from '@ubo/losse-sjedel'
import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

export default function PostsHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [isStart, setStart] = useState(true)

  return (
    <section data-component="PostsHighlighted" className="section">
      <div className="container">
        {fields.description && <Content className="content mb-6 sm:mb-10 md:mb-12 lg:mb-16">{fields.description}</Content>}
      </div>
      <div className="container max-sm:pr-0">
        <Slider
          breakpoints={{
            1280: {
              slidesPerView: 3.3
            },
            1024: {
              slidesPerView: 3.3
            },
            768: {
              slidesPerView: 2.8
            },
            0: {
              slidesPerView: 1.8
            }
          }}
          onSlideChange={(swiper) => {
            if (swiper.activeIndex === 0 && !isStart) {
              setStart(true)
            } else if (swiper.activeIndex !== 0 && isStart) {
              setStart(false)
            }
          }}
        >
          <AnimatePresence>
            {!isStart && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute left-0 top-0 h-full w-16 max-sm:hidden lg:w-24 pointer-events-none z-10 bg-gradient-to-l from-transparent to-rc-litten"
              />
            )}
          </AnimatePresence>

          {fields?.items?.map((item) => (
            <Slide key={item?.id} className="pt-3 pr-5 xl:pr-8">
              <ProductComponent post={item as Product} />
            </Slide>
          ))}

          <div className="absolute right-0 top-0 h-full w-16 max-sm:hidden lg:w-24 pointer-events-none z-10 bg-gradient-to-r from-transparent to-rc-litten" />
        </Slider>
        {fields.link && (
          <div className="mt-12 flex justify-center">
            <LosseLink src={fields.link} className="btn--light" />
          </div>
        )}
      </div>
    </section>
  )
}
