/* eslint-disable react-hooks/exhaustive-deps */
import { useFetcher, LossePlaatjie } from '@ubo/losse-sjedel'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import type { SimpleProduct, VariableProduct } from '~/graphql/types'

import { currency } from '~/services/utils'
import Content from '../Content'
import useCartMessage from '~/services/useCartMessage'
import { AddToCartButton } from './GlobalAddToCartButton'
import { NoImage } from '~/components/flex/ProductsWithFilters/Product'
import clsx from 'clsx'

export default function SimpleModal({ product, onClose }: { product: SimpleProduct | VariableProduct; onClose: () => void }) {
  const cartFetcher = useFetcher()
  const [isCartMessageAvailable] = useCartMessage(cartFetcher)

  const [showExtraNote, setShowExtraNote] = useState(false)
  const [extraNote, setExtraNote] = useState<string | null>(null)

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') onClose()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  const IMAGE_CLASSES = clsx('rounded-2xl aspect-[16/10] object-cover relative w-full')

  const extraData: Record<string, string> = {}
  if (showExtraNote && extraNote) {
    extraData['Specifieke wensen'] = extraNote
  }

  return (
    <cartFetcher.Form
      method="post"
      action="/api/cart"
      className="fixed left-0 top-0 w-full h-full  flex items-center justify-center z-[1000]"
    >
      <input type="hidden" name="_action" value="add" />
      <input type="hidden" name="productId" value={product.databaseId} />
      {Object.values(extraData).length && <input type="hidden" name="extraData" value={JSON.stringify(extraData)} />}

      <div onClick={() => onClose()} className="bg-black/50 w-full h-full absolute left-0 top-0" />
      <motion.div
        initial={{
          scale: 0.8,
          opacity: 0
        }}
        animate={{
          scale: 1,
          opacity: 1
        }}
        className="bg-white rounded-2xl relative z-10  max-w-xl w-full mx-4"
      >
        {product.image && <LossePlaatjie type="fluid" src={product.image} className={IMAGE_CLASSES} autoheight={false} maxwidth={800} />}
        {!product.image && (
          <div className={clsx(IMAGE_CLASSES, 'bg-rc-quilladin')}>
            <NoImage categories={product.productCategories} />
          </div>
        )}

        <button onClick={() => onClose()} className="absolute right-0 top-0 m-4">
          <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none" viewBox="0 0 34 34">
            <g clipPath="url(#clip0_1146_4389)">
              <path
                fill="#fff"
                d="M34 2.003L31.997 0 17 14.997 2.003 0 0 2.003 14.997 17 0 31.997 2.003 34 17 19.003 31.997 34 34 31.997 19.003 17 34 2.003z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_1146_4389">
                <path fill="#fff" d="M0 0H34V34H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </button>

        <div className="p-4 lg:p-6">
          <h2 className="text-lg sm:text-xl lg:text-2xl mb-1 font-bold">{product.name}</h2>
          <Content className="content mb-4">{product.shortDescription}</Content>

          <button
            type="button"
            className="font-bold flex items-center gap-x-2 mb-4 btn--dark"
            onClick={() => setShowExtraNote(!showExtraNote)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="feather feather-message-circle"
              viewBox="0 0 24 24"
            >
              <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
            </svg>
            Specifieke wensen?
          </button>

          {showExtraNote && (
            <input
              type="text"
              name="extraNote"
              placeholder="Bijvoorbeeld: zonder tomaat"
              className="w-full border border-black/10 rounded-lg p-2 mb-4"
              onChange={(e) => setExtraNote(e.target.value)}
            />
          )}

          <div className="grid items-end mt-4">
            {/* <Allergens product={product} /> */}
            <div className="flex items-center">
              <AnimatePresence>
                {isCartMessageAvailable && !cartFetcher.data.success && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="text-right pr-4 form-duuf-error"
                  >
                    Kon product niet toevoegen aan winkelmand
                  </motion.div>
                )}
              </AnimatePresence>

              <AddToCartButton
                className="ml-auto"
                cartFetcher={cartFetcher}
                showMessage={isCartMessageAvailable}
                text={currency(product.regularPrice || '', product.onSale ? product.salePrice || undefined : undefined)}
                onSuccess={() => {
                  setTimeout(() => {
                    onClose()
                  }, 1500)
                }}
              />
            </div>
          </div>
        </div>
      </motion.div>
    </cartFetcher.Form>
  )
}
