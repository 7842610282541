import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUSPsExtended({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentUSPsExtended" className={clsx('bg-rc-sandaconda', fields.spaceTop ? 'section' : 'section--bottom')}>
      <div className="container lg:max-w-screen-lg">
        {fields.description && <Content className="content text-white mb-12">{fields?.description}</Content>}

        {fields.links && (
          <div className="grid grid-cols-1 gap-10 md:gap-20 lg:gap-28 md:px-14">
            {fields.links.map((link, index) => (
              <div key={index}>
                <div className="sm:flex gap-10 lg:gap-14">
                  <LosseLink
                    className="max-sm:max-w-[100px] sm:w-[30%] flex flex-col justify-start sm:justify-center group hover:-translate-y-3 focus:scale-95 transition-all"
                    to={link?.link?.url || '/'}
                    target={link?.link?.target || ''}
                  >
                    <LossePlaatjie
                      src={link?.icon}
                      className="group-hover:svg--white w-full object-contain mx-auto max-w-[200px] md:max-w-[225px]"
                      maxwidth={300}
                    />
                    <h2 className="text-white mt-4 sm:text-center md:text-lg lg:text-xl">{link?.link?.title}</h2>
                  </LosseLink>
                  <div className="sm:w-[70%] flex flex-col justify-center mt-1 sm:mt-2">
                    <h3 className="text-rc-shedinja text-sm sm:text-lg font-rc-plus font-bold">{link?.label}</h3>
                    <Content className="content mt-3 sm:mt-2 text-white">{link?.description}</Content>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
