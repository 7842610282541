/* eslint-disable react-hooks/exhaustive-deps */
import { type LoaderData, useLoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import { Background } from '~/components/flex/Banner/BannerLanding'
import { AnimatePresence, motion } from 'framer-motion'
import type { CommerceLoaderData } from '~/services/session.server'
import type { GooglePlace } from '~/templates/page'

export default function HeaderMobile({ isMenuOpen, setMenuOpen }: { isMenuOpen: boolean; setMenuOpen: (value: boolean) => void }) {
  const { setScrollable } = useLosseLayout()
  const { place } = useLoaderData<LoaderData & CommerceLoaderData & { place: GooglePlace }>()

  return (
    <AnimatePresence>
      {isMenuOpen && (
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="fixed left-0 top-0 z-50 flex flex-col h-screen w-screen items-center justify-center bg-rc-sandaconda"
        >
          <div
            className="absolute right-5 top-5 text-center"
            role="button"
            onClick={() => {
              setMenuOpen(false)

              setScrollable(true)
            }}
            aira-label="Close menu"
          >
            <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[2px] before:rotate-45 before:bg-white before:[content:''] after:absolute after:h-[33px] after:w-[2px] after:-rotate-45 after:bg-white after:[content:'']" />
          </div>

          <Background />

          <ul className="m-0 w-8/12 pl-0 text-white">
            <HeaderMenu />
          </ul>

          {place && place.isOpen !== undefined && <div className="mt-10 text-white">Vandaag open van {place.openingsToday} uur</div>}
          {/* {!place?.isOpen && <div className="mt-10 text-white">Wij zijn gesloten</div>} */}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
