import { type Fetcher } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'

export default function useCartMessage(cartFetcher: Fetcher) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!isVisible) return

    const timeout = setTimeout(() => {
      setIsVisible(false)
    }, 3500)

    return () => clearTimeout(timeout)
  }, [isVisible])

  useEffect(() => {
    if (cartFetcher.data && cartFetcher.state === 'idle') setIsVisible(true)
  }, [cartFetcher])

  return [isVisible]
}
