import Content from '~/components/elements/Content'
import { motion } from 'framer-motion'
import type { Page_Flexcontent_Flex_Faq } from '~/graphql/types'
import { useState } from 'react'
import { useLoaderData } from '@ubo/losse-sjedel'
import type { GooglePlace } from '~/templates/page'

export default function FaqDefault({ fields }: { fields: Page_Flexcontent_Flex_Faq }) {
  const [open, setOpen] = useState<number | null>(null)
  const { place } = useLoaderData<{ place: GooglePlace }>()

  return (
    <section data-component="FaqDefault" className="section" id="veelgestelde-vragen">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-12 gap-6 md:gap-12">
          <div className="col-span-1 lg:col-span-5">
            <Content className="content mb-4">{fields?.description}</Content>
          </div>

          <div className="col-span-1 md:col-span-2 lg:col-span-7">
            {fields?.questions?.map((question, index) => (
              <div key={index} className="border-b border-rc-quilladin/30">
                <button
                  type="button"
                  className="flex px-0 items-center justify-between py-4 lg:py-7 w-full group"
                  onClick={() => {
                    if (index === open) return setOpen(null)

                    setOpen(index)
                  }}
                >
                  <h3 className="sm:text-lg lg:text-xl font-bold group-hover:text-rc-shedinja text-left">{question?.question}</h3>
                  <div className="relative group-hover:text-rc-shedinja">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1261_9437)">
                        <path d="M24 10.9997H0V12.9997H24V10.9997Z" fill="currentColor" />
                      </g>
                    </svg>
                    <motion.svg
                      animate={open === index ? 'open' : 'closed'}
                      variants={{
                        open: { rotate: 0 },
                        closed: { rotate: 90 }
                      }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute top-0 left-0"
                    >
                      <g clipPath="url(#clip0_1261_9437)">
                        <path d="M24 10.9997H0V12.9997H24V10.9997Z" fill="currentColor" />
                      </g>
                    </motion.svg>
                  </div>
                </button>
                <motion.div
                  animate={open === index ? 'open' : 'closed'}
                  variants={{
                    open: { height: 'auto', opacity: 1 },
                    closed: { height: 0, opacity: 0 }
                  }}
                  className="h-0 overflow-hidden"
                >
                  <Content className="content pb-4 font-light">{index === 0 ? place?.openings : question?.answer}</Content>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
