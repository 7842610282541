import { Combobox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment } from 'react'
import type { VariationOption } from '~/services/utils'

export default function VariationSelect({
  options,
  setSelected,
  selected
}: {
  options: VariationOption[]
  selected: VariationOption
  setSelected: (value: VariationOption) => void
}) {
  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative sm:min-w-[150px] xl:min-w-[200px] lg:mt-1">
        <div className="relative w-full cursor-default overflow-hidden form-duuf-input">
          <Combobox.Button className="relative w-full flex items-center">
            <span className="sm:mr-3 w-full text-left">{selected.value}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-gray-400 flex-none"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
            </svg>
          </Combobox.Button>
        </div>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Combobox.Options className="absolute lg:mt-1 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option) => (
              <Combobox.Option
                key={option.attributeId}
                className={({ active }) =>
                  clsx(
                    `relative cursor-default select-none py-1 sm:py-2 px-4`,
                    active ? 'bg-rc-shedinja text-white' : 'text-gray-900',
                    option.disabled && 'opacity-50 cursor-not-allowed'
                  )
                }
                disabled={option.disabled}
                value={option}
              >
                {({ selected }) => (
                  <span className={`block truncate max-sm:text-sm ${selected ? 'font-medium' : 'font-normal'}`}>{option.value}</span>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}
