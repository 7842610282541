import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentUSPs from './ContentUSPs'
import ContentImages from './ContentImages'
import ContentUSPsExtended from './ContentUSPsExtended'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    usps: ContentUSPs,
    usps_extend: ContentUSPsExtended,
    images: ContentImages
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
