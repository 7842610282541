import { type PropsWithChildren, createContext, useContext, useState, useEffect } from 'react'
import { type LoaderData, LosseLayout, useLoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'
import clsx from 'clsx'
import { type CommerceLoaderData } from '~/services/session.server'

type TypeHeader = 'default' | 'transparent'

interface LayoutContextProps {
  typeHeader?: TypeHeader
  isCartOpen: boolean
  setIsCartOpen: (open: boolean) => void
}

export const LayoutContext = createContext<LayoutContextProps | null>(null)

export const useLayout = () => {
  const context = useContext(LayoutContext)

  return context as LayoutContextProps
}

export default function Layout({ children }: PropsWithChildren<Record<string, unknown>>) {
  return (
    <LosseLayout>
      <Content>{children}</Content>
    </LosseLayout>
  )
}

function Content({ children }: React.PropsWithChildren<Record<string, unknown>>) {
  const data = useLoaderData<LoaderData & CommerceLoaderData & { typeHeader: TypeHeader }>()
  const { isScrollable, isSticky } = useLosseLayout()
  const [isCartOpen, setIsCartOpen] = useState(false)

  useEffect(() => {
    if (isCartOpen) {
      document.body.style.overflow = 'hidden'
    } else if (!isCartOpen) {
      document.body.style.overflow = 'auto'
    }
  }, [isCartOpen])

  return (
    <LayoutContext.Provider value={{ typeHeader: isSticky ? 'default' : data.typeHeader || 'default', isCartOpen, setIsCartOpen }}>
      <div className={clsx(isScrollable ? `h-full` : `h-screen overflow-hidden`)}>
        <Header />
        <div className="top--triangle-wrapper">
          <div className="top--triangle" />
        </div>
        <main className="min-h-calc(100vh-400px) pt-[200px]">{children}</main>
        <Footer />
      </div>

      <button
        type="button"
        className={clsx(
          isCartOpen && 'pointer-events-none',
          'group max-lg:hidden fixed right-10 bottom-10 bg-rc-quilladin items-center py-2 pl-4 pr-2 font-rc-plus shadow btn--pseudo btn--base hover:bg-rc-shedinja z-50'
        )}
        onClick={() => setIsCartOpen(true)}
      >
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="text-white h-5 w-5">
            <g fill="currentColor" clipPath="url(#clip0_1329_2587)">
              <path d="M24 3H4.242L4.2 2.649A3 3 0 001.222 0H0v2h1.222a1 1 0 01.993.883L3.8 16.351A3 3 0 006.778 19H20v-2H6.778a1 1 0 01-.993-.884L5.654 15h16.182L24 3zm-3.836 10H5.419l-.941-8h17.129l-1.443 8zM7 24a2 2 0 100-4 2 2 0 000 4zM17 24a2 2 0 100-4 2 2 0 000 4z"></path>
            </g>
          </svg>
        </div>
        <div className="text-white px-3">Winkelmand</div>
        <div className="bg-rc-shedinja transition-colors group-hover:bg-rc-quilladin rounded-full w-8 h-8 aspect-square flex items-center justify-center text-sm text-white">
          {data.cart.contents.itemCount}
        </div>
      </button>
    </LayoutContext.Provider>
  )
}
