/* eslint-disable react-hooks/exhaustive-deps */
import type { LoaderData } from '@ubo/losse-sjedel'
import { useNavigation, losseLoader, useFetchers, useLoaderData, Form, useFetcher } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Fragment, forwardRef, useEffect, useRef, useState } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import nl from 'date-fns/locale/nl'
import { CartBreadcrumbs } from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import LoadingOverlay from '~/components/elements/LoadingOverlay'
import NoProductsInCart from '~/components/elements/NoProductsInCart'
import Checkbox from '~/components/elements/form-elements/Checkbox'
import Input from '~/components/elements/form-elements/Input'
import SubmitButton from '~/components/elements/form-elements/SubmitButton'
import Textarea from '~/components/elements/form-elements/Textarea'
import type { PaymentGateway, RootQueryToPaymentGatewayConnection, Cart as CartType, GooglePlace } from '~/graphql/types'
import type { CommerceLoaderData } from '~/services/session.server'
import { currency, getDeliveryInfoData, shippingRateToName } from '~/services/utils'

registerLocale('nl', nl)

export default function Checkout() {
  const { cart, customer, flashMessage, place } = useLoaderData<LoaderData & CommerceLoaderData & { place: GooglePlace }>()
  const [touched, setTouched] = useState<Record<string, boolean>>({})
  const ref = useRef<HTMLFormElement>(null)
  const cartFetcher = useFetcher()
  const couponFetcher = useFetcher<{ error: boolean; message: string }>()
  const gatewaysFetcher = useFetcher<{ paymentGateways: RootQueryToPaymentGatewayConnection }>()
  const fetchers = useFetchers()
  const navigation = useNavigation()
  const { groups } = getDeliveryInfoData(cart as unknown as CartType)
  const [pickupDate, setPickupDate] = useState<Date>(cart.customDeliveryInfo?.date ? new Date(cart.customDeliveryInfo.date) : new Date())
  const [laterPickupDate, setLaterPickupDate] = useState<Date | null>(
    cart.customDeliveryInfo?.later_date ? new Date(cart.customDeliveryInfo.later_date) : null
  )

  const isAnyFetcherBusy = Object.values(fetchers).some((fetcher) => fetcher.state !== 'idle')

  function updateDeliveryState() {
    if (!ref.current) return
    const formData = new FormData(ref.current)
    formData.append('_action', 'update_customer')

    cartFetcher.submit(formData, {
      action: '/api/cart',
      method: 'post'
    })
  }

  function updateShippingMethod() {
    if (!ref.current) return
    const formData = new FormData(ref.current)
    formData.append('_action', 'update_shipping_method')

    cartFetcher.submit(formData, {
      action: '/api/cart',
      method: 'post'
    })
  }

  useEffect(() => {
    const query = losseLoader({
      name: 'PaymentGateways'
    })

    gatewaysFetcher.load(query)
  }, [])

  useEffect(() => {
    if (!ref.current) return

    ref.current.addEventListener('change', (event) => {
      if (!event.target) return

      const target = event.target as HTMLFormElement

      touched[target.name] = true
      setTouched({ ...touched })

      switch (target.name) {
        case 'shipping_postcode':
        case 'billing_postcode':
          updateDeliveryState()

        default:
          break
      }
    })
  }, [])

  function addColonToMiddle(time?: string) {
    return time?.length === 4 ? time.slice(0, 2) + ':' + time.slice(2) : ''
  }

  function getTimeSlots(startTime: string, endTime: string, interval: number, minimumBufferInMinutes: number, disableSameDay: boolean) {
    const timeSlots = []
    const bufferTime = new Date()
    bufferTime.setMinutes(bufferTime.getMinutes() + minimumBufferInMinutes) // set buffer time

    const openingPeriod = place?.openingPeriods?.find((period) => period?.open?.day === pickupDate.getDay())?.open
    const closingPeriod = place?.openingPeriods?.find((period) => period?.close?.day === pickupDate.getDay())?.close

    if (!openingPeriod || !closingPeriod) return []

    if (pickupDate && (pickupDate.getDay() === 1 || pickupDate.getDay() === 2 || pickupDate.getDay() === 3)) {
      startTime = '08:00'
      endTime = '18:00'
    }

    if (pickupDate && (pickupDate.getDay() === 4 || pickupDate.getDay() === 5)) {
      startTime = '08:00'
      endTime = '19:00'
    }

    if (pickupDate && pickupDate.getDay() === 6) {
      startTime = '08:00'
      endTime = '16:30'
    }

    for (let i = 0; i < (24 * 60) / interval; i++) {
      const hours = Math.floor((i * interval) / 60)
      const minutes = (i * interval) % 60
      const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

      // let maybeSaturdayEndTime = endTime
      // let maybeSaturdayStartTime = startTime

      // if (closingPeriod) maybeSaturdayEndTime = addColonToMiddle(closingPeriod.time as string)
      // if (openingPeriod) maybeSaturdayStartTime = addColonToMiddle(openingPeriod.time as string)

      if (time < startTime || time > endTime) {
        continue
      }

      // check if the time is within the specified time in Google Places
      if (time.split(':').join('') < openingPeriod.time || time.split(':').join('') > closingPeriod.time) {
        continue
      }

      const isSameDay =
        pickupDate.getDate() === new Date().getDate() &&
        pickupDate.getMonth() === new Date().getMonth() &&
        pickupDate.getFullYear() === new Date().getFullYear()

      // check if the time is within the buffer time
      const timeSlotDate = new Date()
      timeSlotDate.setHours(hours, minutes)

      if (!disableSameDay && isSameDay && timeSlotDate < bufferTime) {
        continue
      }

      timeSlots.push(time)
    }

    return timeSlots
  }

  const hasItems = cart.contents.itemCount > 0

  return (
    <section data-component="Checkout" className="container pb-10">
      <CartBreadcrumbs type="checkout" />

      <Form
        className="max-w-screen-xl xl:px-8 mx-auto grid grid-cols-1 sm:grid-cols-3 cart--grid--gap items-start"
        action="/api/checkout"
        method="POST"
        id="checkout"
        ref={ref}
      >
        <div className={clsx(hasItems && 'bg-white', 'col-span-1 sm:col-span-2 rounded-2xl p-4 md:p-6 relative flex flex-col gap-8')}>
          {!hasItems && <NoProductsInCart />}

          {hasItems && (
            <>
              <h2 className="font-bold text-lg col-span-4">Je gegevens</h2>
              <div className="max-w-xl grid grid-cols-1 sm:grid-cols-4 gap-4">
                <Input
                  type="text"
                  className="sm:col-span-2"
                  label="Voornaam"
                  name="billing_first_name"
                  required
                  defaultValue={customer?.billing?.firstName || ''}
                />
                <Input
                  type="text"
                  className="sm:col-span-2"
                  label="Achternaam"
                  name="billing_last_name"
                  required
                  defaultValue={customer?.billing?.lastName || ''}
                />
                <Input
                  type="text"
                  className="sm:col-span-3"
                  label="Straatnaam"
                  name="billing_address_1"
                  required
                  defaultValue={customer?.billing?.address1 || ''}
                />
                <Input
                  type="text"
                  className="sm:col-span-1"
                  label="Huis nr."
                  name="billing_address_2"
                  required
                  defaultValue={customer?.billing?.address2 || ''}
                />
                <Input
                  type="text"
                  className="sm:col-span-2"
                  label="Woonplaats"
                  name="billing_city"
                  required
                  defaultValue={customer?.billing?.city || ''}
                />
                <input type="hidden" name="billing_country" value="NL" />
                {/* <div className="sm:col-span-2 flex flex-col"> */}
                <Input
                  type="text"
                  className="sm:col-span-2"
                  label="Postcode"
                  name="billing_postcode"
                  required
                  defaultValue={customer?.billing?.postcode || ''}
                />
                {touched['billing_postcode'] &&
                  cartFetcher.state === 'idle' &&
                  cart.availableShippingMethods?.find((method) => method?.rates) === undefined && (
                    <div className="text-red-500 col-span-4">Deze postcode is buiten onze transportzone.</div>
                  )}
                {/* </div> */}
                <Input
                  type="text"
                  className="sm:col-span-2"
                  label="Telefoonnummer"
                  name="billing_phone"
                  required
                  defaultValue={customer?.billing?.phone || ''}
                />
                <Input
                  type="email"
                  className="sm:col-span-2"
                  label="E-mailadres"
                  name="billing_email"
                  required
                  defaultValue={customer?.billing?.email || ''}
                />
                <Textarea name="customer_note" label="Opmerkingen" className="sm:col-span-4 h-[100px]" />
              </div>

              <div className="grid grid-cols-4 gap-x-4 max-w-lg">
                {groups.map((group) => {
                  const currentPickupDate = group.id === 'same_day_pickup' ? pickupDate : laterPickupDate
                  const pickupIsToday =
                    currentPickupDate && new Date(currentPickupDate).toLocaleDateString() === new Date().toLocaleDateString()

                  return (
                    <>
                      {group.id === 'same_day_pickup' ? (
                        <>
                          <h2 className="font-bold col-span-4  sm:text-lg text-base">
                            <span className="text-rc-shedinja">Boulangerie</span> – Afhalen of bezorgen?
                          </h2>
                          <p className="col-span-4 mb-2 italic sm:text-base text-sm">
                            RengersCorner bezorgt alleen in Bunschoten-Spakenburg <br /> (Vul je gegevens om bezorgen te selecteren)
                          </p>
                          <div className="col-span-4 mb-3 flex flex-col gap-y-1">
                            {!(cart.availableShippingMethods && cart.availableShippingMethods[0]?.rates) && (
                              <div className="text-red-500">
                                Wij bezorgen alleen in Bunschoten-Spakenburg. <br /> Kies voor afhalen of een andere postcode.
                              </div>
                            )}
                            {cart.availableShippingMethods &&
                              cart.availableShippingMethods[0]?.rates?.map((rate) => (
                                <label className="flex items-center gap-x-4" key={rate?.id}>
                                  <input
                                    onChange={() => updateShippingMethod()}
                                    type="radio"
                                    name="shipping_method"
                                    value={rate?.id}
                                    defaultChecked={(cart.chosenShippingMethods && cart.chosenShippingMethods[0] === rate?.id) || false}
                                  />

                                  <span>
                                    <strong>{rate?.label === 'Local pickup' ? 'Afhalen' : rate?.label}</strong>{' '}
                                    {rate?.cost === '0.00' ? '(Gratis)' : `(${currency(rate?.cost || '0.00')})`}
                                  </span>
                                </label>
                              ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <h2 className="font-bold col-span-4 mb-3 text-lg ">
                            <span className="text-rc-shedinja">Bakery en Gelateria</span> – Datum en tijd van afhalen
                          </h2>

                          {groups.length === 1 && (
                            <input
                              type="hidden"
                              name="shipping_method"
                              value={
                                (cart.availableShippingMethods &&
                                  cart.availableShippingMethods[0]?.rates &&
                                  cart.availableShippingMethods[0]?.rates[0]?.id) ||
                                ''
                              }
                            />
                          )}
                        </>
                      )}

                      <div className="col-span-2">
                        <ReactDatePicker
                          name={group.id === 'same_day_pickup' ? 'pickup_date' : 'later_pickup_date'}
                          id={group.id === 'same_day_pickup' ? 'pickup_date' : 'later_pickup_date'}
                          customInput={<DatepickerInput />}
                          placeholderText="Kies een datum"
                          selected={currentPickupDate}
                          dateFormat="EEEE dd-MM-yyyy"
                          calendarStartDay={1}
                          value={pickupIsToday ? 'Vandaag' : undefined}
                          minDate={group.id === 'same_day_pickup' ? new Date() : new Date(pickupDate.getTime() + 24 * 60 * 60 * 1000)}
                          locale="nl"
                          filterDate={(date) => {
                            if (date.getDay() === 0) return false
                            return true
                          }}
                          onChange={(date) => {
                            if (date) {
                              group.id === 'same_day_pickup' ? setPickupDate(date) : setLaterPickupDate(date)
                            } else {
                              group.id === 'same_day_pickup' ? setPickupDate(new Date()) : setLaterPickupDate(null)
                            }
                          }}
                        />

                        <input
                          type="hidden"
                          name={group.id}
                          id={group.id}
                          value={group.id === 'same_day_pickup' ? pickupDate.toISOString() : laterPickupDate?.toISOString()}
                        />
                      </div>

                      <div className="col-span-2 mb-4">
                        <select
                          name={group.id === 'same_day_pickup' ? 'pickup_time' : 'later_pickup_time'}
                          id={group.id === 'same_day_pickup' ? 'pickup_time' : 'later_pickup_time'}
                          className="form-duuf-input md:h-[42px] form-duuf-select pr-10 max-lg:text-sm"
                          defaultValue={
                            group.id === 'same_day_pickup' ? cart.customDeliveryInfo?.time : cart.customDeliveryInfo?.later_time
                          }
                        >
                          <option value="">Kies een tijd</option>
                          {getTimeSlots('08:00', '19:00', 15, 30, group.id !== 'same_day_pickup').map((time) => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </div>

                      {groups.length === 2 && group.id === 'same_day_pickup' && <hr className="col-span-4 mb-3 mt-1" />}
                    </>
                  )
                })}
              </div>

              <div className="grid grid-cols-4 gap-x-4 max-w-lg">
                <h2 className="font-bold col-span-4 mb-2 text-lg">Kortingscode</h2>
                <Input type="text" className="col-span-2" label="Kortingscode" name="coupon_code" />
                <div className="col-span-2 flex items-center">
                  <SubmitButton
                    onClick={() => {
                      if (!ref.current) return
                      const formData = new FormData(ref.current)
                      formData.append('_action', 'apply_coupon')
                      couponFetcher.submit(formData, {
                        action: '/api/cart',
                        method: 'post'
                      })
                    }}
                    className="btn"
                    type="button"
                    loading={couponFetcher.state === 'submitting'}
                  >
                    Pas <span className="max-sm:hidden">kortingscode </span>toe
                  </SubmitButton>
                </div>

                {couponFetcher.data && couponFetcher.state !== 'submitting' && (
                  <div
                    dangerouslySetInnerHTML={{ __html: couponFetcher.data.message }}
                    className={clsx('col-span-4 mt-1', couponFetcher.data.error ? 'form-duuf-error' : 'text-rc-sandaconda')}
                  />
                )}
              </div>
            </>
          )}
        </div>

        <div className="bg-rc-shedinja text-black rounded-2xl p-4 lg:py-6 lg:px-6 xl:px-10 relative flex overflow-hidden flex-col gap-4">
          {isAnyFetcherBusy && <LoadingOverlay />}
          <h2 className="text-black font-rc-plus font-bold text-lg sm:text-xl xl:text-2xl">Afrekenen</h2>

          {/* <div className="max-sm:text-sm py-3 lg:py-4">
            RengersCorner
            <br />
            {info.addressOne}, {info.addressTwo}
          </div> */}

          {/* <DeliveryInfo customDeliveryInfo={cart.customDeliveryInfo as CartDeliveryInfo} /> */}

          <div className="grid grid-cols-4 gap-y-4">
            <div className="col-span-2 lg:col-span-3">Subtotaal</div>
            <Content className="text-right col-span-2 lg:col-span-1">{currency(cart.subtotal)}</Content>
            <div className="col-span-2 lg:col-span-3">Bezorgkosten</div>
            <Content className="text-right col-span-2 lg:col-span-1">
              <Content>{cart.shippingTotal}</Content>
              <strong>{cart.chosenShippingMethods && shippingRateToName(cart as unknown as CartType)} </strong>
            </Content>
            <div className="col-span-2 lg:col-span-3">BTW</div>
            <Content className="text-right col-span-2 lg:col-span-1">{currency(cart.totalTax)}</Content>
            {cart.appliedCoupons?.map((coupon) => (
              <Fragment key={coupon?.code}>
                <div className="flex flex-col col-span-3">
                  <span>Kortingscode:</span>

                  <div className="flex items-center gap-x-1">
                    <b>{coupon?.code}</b>
                    <button
                      onClick={() => {
                        if (!ref.current) return
                        if (!coupon?.code) return
                        const formData = new FormData()
                        formData.append('_action', 'remove_coupon')
                        formData.append('coupon_code', coupon?.code)
                        cartFetcher.submit(formData, {
                          action: '/api/cart',
                          method: 'post'
                        })
                      }}
                      title="Kortingscode verwijderen"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <Content className="text-right">{`-${coupon?.discountAmount}`}</Content>
              </Fragment>
            ))}

            <div className="cart--total col-span-2 lg:col-span-3">Totaal</div>
            <Content className="cart--total text-right col-span-2 lg:col-span-1">{currency(cart.total)}</Content>

            <strong className="mb-1 mt-4 block col-span-4 lg:col-span-2">Betaalmethodes</strong>
            <div className="flex flex-col col-span-4 lg:col-span-2 gap-y-2 mb-1 lg:pl-3 lg:mt-4 -mt-4">
              {gatewaysFetcher.data?.paymentGateways?.nodes?.map((node) => {
                const gateway = node as PaymentGateway
                return (
                  <label className="flex items-center" key={gateway?.id}>
                    <input
                      type="radio"
                      required
                      defaultChecked={gateway.id === 'mollie_wc_gateway_ideal'}
                      name="payment_method"
                      value={gateway?.id}
                      id={gateway?.id}
                    />
                    <span className="ml-2 mr-4">{gateway?.title}</span>
                    <Content>{gateway?.icon}</Content>
                  </label>
                )
              })}
            </div>
          </div>

          <Checkbox
            label={`Ik heb de <a target="_blank" class="text-rc underline" href="/privacyverklaring/">privacyverklaring</a> gelezen en ga hiermee akkoord`}
            name="terms"
            required
          />

          {flashMessage && (
            <div className="text-red-600">
              <Content className="children-ul:list-disc children-ul:ml-5">{flashMessage}</Content>
            </div>
          )}

          <SubmitButton
            type="submit"
            loading={navigation.state === 'submitting'}
            className="btn flex justify-center items-center lg:py-3 lg:text-lg"
            disabled={!hasItems}
          >
            Bestelling bevestigen
          </SubmitButton>
        </div>
      </Form>
    </section>
  )
}

const DatepickerInput = forwardRef<HTMLInputElement>(({ ...rest }, ref) => (
  <input {...rest} className="form-duuf-input md:h-[42px] max-lg:text-sm first-letter:capitalize" readOnly ref={ref} />
))
DatepickerInput.displayName = 'Input'
