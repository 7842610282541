import type { ProductTagConnection } from '~/graphql/types'

export default function Allergens({ tags, withTitle = false }: { tags: ProductTagConnection; withTitle?: boolean }) {
  if (!tags || tags.nodes.length === 0) return null

  return (
    <div className="flex items-center gap-x-4 mt-8">
      {withTitle && <strong className="lg:text-lg">Allergenen</strong>}
      <div className="flex items-center gap-x-2">
        {tags.nodes.map((allergen) => (
          <div key={allergen.slug} className="flex items-center gap-x-2" title={allergen.name || ''}>
            <img className="w-10 h-10 object-contain" src={`/static/allergenen/${allergen.slug}.png`} alt={allergen.name || ''} />
          </div>
        ))}
      </div>
    </div>
  )
}
