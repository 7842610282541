/* eslint-disable react-hooks/exhaustive-deps */
import type { FetcherWithComponents, LoaderData } from '@ubo/losse-sjedel'
import { LosseLink, Link, useFetcher, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { Tooltip } from 'react-tooltip'
import type { CartItem } from '~/graphql/types'
import type { CommerceLoaderData } from '~/services/session.server'
import { currency, splitByDeliverability, zoomClassesForCertainCategories } from '~/services/utils'
import Loading from './Loading'
import Quantity from './Quantity'
import NoProductsInCart from './NoProductsInCart'
import { NoImage } from '../flex/ProductsWithFilters/Product'

export default function Cart({ onClose }: { onClose: () => void }) {
  const { cart } = useLoaderData<LoaderData & CommerceLoaderData>()
  const cartFetcher = useFetcher()
  const formRef = useRef<HTMLFormElement>(null)

  const [samedayProducts, nextDay] = splitByDeliverability(cart?.contents?.nodes || ([] as CartItem[]))

  const groups = [
    {
      title: 'Boulangerie',
      items: samedayProducts
    },
    {
      title: 'Bakery & Gelateria',
      items: nextDay
    }
  ]

  function handleClickOutside(event: MouseEvent) {
    if (formRef.current && !formRef.current.contains(event.target as Node)) onClose()
  }

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') onClose()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [formRef])

  const isEmpty = cart?.contents?.nodes?.length === 0

  return (
    <cartFetcher.Form action="/api/cart" method="POST" id="cart" ref={formRef}>
      <input type="hidden" name="_action" value="update" />

      <div className="relative">
        <div className="px-4 py-4 lg:py-6 flex justify-center relative items-center">
          <LosseLink to="/winkelmand/" className="text-lg lg:text-xl text-center font-rc-plus font-bold hover:underline">
            Winkelmand
          </LosseLink>

          <button
            type="button"
            onClick={onClose}
            className="btn--pseudo btn--base hover:bg-rc-shedinja flex items-center justify-center right-4 w-8 h-8 top-0 bottom-0 my-auto absolute"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {groups
          .filter((group) => group.items.length !== 0)
          .map((group) => (
            <div key={group.title} className={clsx('p-4', group.title === 'Direct afhalen' ? 'bg-white' : 'bg-rc-litten')}>
              <span className="text-rc-quilladin opacity-50 font-bold">{group.title}</span>

              {group.items.map((item) => (
                <CartItemComponent key={item.key} item={item} fetcher={cartFetcher} formRef={formRef} />
              ))}

              {/* afhalen
              <div className="flex flex-col mt-4">
                <strong className="mb-2">Afhalen</strong>
                <div className="flex">
                  <input
                    type="date"
                    placeholder="Datum van afhalen"
                    className="form-duuf-input px-2 py-1 !rounded-full"
                    name="pickup_date"
                  />
                </div>
              </div> */}
            </div>
          ))}

        {isEmpty && <NoProductsInCart className="bg-white" />}

        {!isEmpty && (
          <>
            <div className="flex justify-between text-lg py-5 px-8 font-bold">
              <span>Totaal</span>
              <span dangerouslySetInnerHTML={{ __html: currency(cart.total || '0') }} />
            </div>

            <div className="flex flex-col px-4 mb-2">
              <Link className="btn w-full text-center items-center justify-center text-lg" to="/winkelmand/">
                {cartFetcher.state !== 'idle' && <Loading className="text-white mr-2" />}
                <span>Afrekenen</span>
              </Link>
            </div>
          </>
        )}

        <span className="flex items-center justify-center gap-x-2 mb-4 mt-4 max-md:text-sm">
          Veilig betalen met <img src="/static/ideal-white.svg" alt="IDeal Logo" />
        </span>
      </div>
    </cartFetcher.Form>
  )
}

/**
 * Since simple products can also have variation through attributes, we need to
 * convert the cart-item extraData into the same format as the variation attributes.
 */
export function variationAttributes(item: CartItem): {
  key: string
  value: string
}[] {
  let attributes: { key: string; value: string }[] = []

  if (item.variation?.attributes) {
    attributes = item.variation.attributes.reduce((acc, attribute) => {
      if (!attribute?.label || !attribute?.value) return acc
      acc.push({
        key: attribute.label,
        value: attribute.value
      })
      return acc
    }, [] as { key: string; value: string }[])
  }

  if (item.extraData && item.extraData?.length > 0) {
    attributes = item.extraData.reduce((acc, attribute) => {
      if (!attribute?.key || !attribute?.value) return acc
      acc.push({
        key: attribute.key,
        value: attribute.value
      })
      return acc
    }, [] as { key: string; value: string }[])
  }

  return attributes
}

function CartItemComponent({
  item,
  fetcher,
  formRef
}: {
  item: CartItem
  fetcher: FetcherWithComponents<unknown>
  formRef: React.RefObject<HTMLFormElement>
}) {
  const IMAGE_CLASSES = clsx('max-w-[75px] min-w-[75px] rounded-lg product--image--wrapper')
  const attributes = variationAttributes(item)
  return (
    <div key={item.key} className="flex justify-start gap-x-4 items-center mt-4">
      {item.product?.node.image && (
        <div className={IMAGE_CLASSES}>
          <LossePlaatjie className={zoomClassesForCertainCategories(item.product.node)} src={item.product?.node.image} maxwidth={300} />
        </div>
      )}
      {!item.product?.node.image && (
        <div className={IMAGE_CLASSES}>
          <NoImage categories={item.product?.node.productCategories} />
        </div>
      )}

      <div className="flex flex-col">
        <LosseLink to={`/product/${item.product?.node.slug}/`} className="flex items-center hover:underline max-sm:text-sm">
          {item.product?.node.name}
        </LosseLink>
        <div className="flex items-center">
          <span className="font-bold text-rc-sandaconda" dangerouslySetInnerHTML={{ __html: currency(item.total || '') }} />

          {attributes && attributes.length > 0 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-4 h-4 ml-1"
              data-tooltip-id={`${item.key}-variations`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          )}
          <Tooltip className="!bg-rc-quilladin flex flex-col" id={`${item.key}-variations`}>
            {attributes.map((attribute) => (
              <span key={attribute?.key}>
                <span className="font-bold">{attribute?.key?.replace('-', ' ')}</span>: {attribute?.value}
              </span>
            ))}
          </Tooltip>
        </div>
      </div>

      <Quantity
        item={item}
        onChange={() => {
          fetcher.submit(formRef.current)
        }}
        className="ml-auto"
      />
    </div>
  )
}
