/* eslint-disable react-hooks/exhaustive-deps */
import { type LoaderData, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import { useEffect, useState } from 'react'
import Logo from '~/components/elements/Logo'
import { useLayout } from '../Layout'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const location = useLocation()
  const { isSticky } = useLosseLayout()
  const { typeHeader } = useLayout()
  const [isMenuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (isMenuOpen) {
      setMenuOpen(false)
    }
  }, [location.pathname, location.hash, location.search])

  return (
    <nav className={clsx('fixed left-0 top-0 right-0 z-50  transition-all')}>
      <div className="container flex h-full items-center justify-between relative">
        <div />
        <LosseLink
          to="/"
          aria-label="Home"
          className={clsx(
            'absolute top-0 left-4 lg:left-12 transition-all w-[115px] h-[125px] md:w-[138px] md:h-[150px]',
            typeHeader === 'transparent' && 'lg:w-[186px] lg:h-[203px]',
            typeHeader === 'default' && 'lg:w-[138px] lg:h-[150px]'
          )}
        >
          <Logo shadow={isSticky} />
        </LosseLink>
        <HeaderDesktop setMenuOpen={setMenuOpen} />
        <HeaderMobile isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
      </div>
    </nav>
  )
}
