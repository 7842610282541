import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import PostsProducts from './PostsProducts'
import PostsBlog from './PostsBlog'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    products: PostsProducts,
    blog: PostsBlog
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
