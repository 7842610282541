import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault">
      <div className="container section mt-10">
        {fields?.image && (
          <div className="h-[450px] w-full rounded-2xl overflow-hidden relative">
            <LossePlaatjie loading="eager" placeholder="blur" className="w-full h-full object-cover" src={fields.image} maxwidth={1920} />
          </div>
        )}
        <div className="pt-5">
          <Breadcrumbs />
        </div>
        {fields?.description && (
          <div className="pt-5">
            <Content className="content">{fields?.description}</Content>
          </div>
        )}
      </div>
    </section>
  )
}
