import { type LoaderData, useLoaderData, wordpressLoader, json, type LoaderFunction, useNavigation } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import progress from 'nprogress'
import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'
import { getCartClient } from '~/services/session.server'
import type { GoogleOpeningPeriodItem, Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { getServerConfig } from '~/services/config.server'
import { date } from '~/services/utils'

export const customWordpressLoader: LoaderFunction = async (data) => {
  const client = await getCartClient(data.request)
  const [page, res] = await Promise.all([wordpressLoader(data), client.getCartAndCustomer()])
  const config = getServerConfig()

  return {
    body: {
      ...page.body,
      cart: res.cart,
      customer: res.customer,
      wpUrl: config.wordpressUrl
    },
    headers: page.headers
  }
}

export interface GooglePlace {
  isOpen: boolean
  day: number
  openings: string
  openingsToday: string
}

export const loader: LoaderFunction = async (data) => {
  const page = await customWordpressLoader(data)

  const hasLandingBanner = !!page.body.page.flexContent.flex.find((flex: Page_Flexcontent_Flex_Banner) => flex.styletype === 'landing')
  page.body.typeHeader = hasLandingBanner ? 'transparent' : 'default'

  // THIS FUNCTION IS ALSO USED IN bestelling.$id.tsx
  const storeOpen = isStoreOpen(page.body.rest.GooglePlaces.openingPeriods)

  page.body.place = {
    isOpen: storeOpen.isOpen,
    openings: googleOpeningDaysLine(page.body.rest.GooglePlaces.openingPeriods),
    openingsToday: googleOpeningTimeLine(page.body.rest.GooglePlaces.openingPeriods),
    openingPeriods: page.body.rest.GooglePlaces.openingPeriods
  }

  if (page.body.page.flexContent.flex.length === 0 && data.request.url.includes('/product/')) {
    page.body.page.flexContent.flex.push({
      styletype: 'two_columns',
      fieldGroupName: 'Product_Flexcontent_Flex_ProductDetail',
      description: page.body.page.excerpt
    })
  }

  return json(page.body, page.headers)
}

const DAYS: string[] = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag']

export function googleOpeningTimeLine(periods: GoogleOpeningPeriodItem[]) {
  try {
    const day = date(new Date()).get('day')
    const _periods = periods.filter((period) => period?.open?.day === day)
    let response = ''

    _periods.forEach((period) => {
      const openTime = `${period?.open?.time}`.replace(/(\d{2})(\d{2})/, '$1:$2')
      const closeTime = `${period?.close?.time}`.replace(/(\d{2})(\d{2})/, '$1:$2')

      response += `${response ? ', ' : ''}${openTime} - ${closeTime}`
    })

    return response
  } catch (error) {
    console.error(error)
    return ''
  }
}

export function googleOpeningDaysLine(periods: GoogleOpeningPeriodItem[]) {
  try {
    const groupedPeriods: { [key: string]: GoogleOpeningPeriodItem[] } = {}

    periods.forEach((period) => {
      const openTime = (period?.open?.time || 2359) as number
      const closeTime = (period?.close?.time || 0) as number
      const key = `${openTime}-${closeTime}`

      if (!groupedPeriods[key]) groupedPeriods[key] = []

      groupedPeriods[key].push(period)
    })

    let response = ''

    Object.values(groupedPeriods).forEach((period) => {
      const firstItem = period[0]
      const lastItem = period[period.length - 1]
      const firstOpenTime = `${firstItem?.open?.time}`.replace(/(\d{2})(\d{2})/, '$1:$2')
      const lastOpenTime = `${lastItem?.close?.time}`.replace(/(\d{2})(\d{2})/, '$1:$2')

      if (firstItem?.open?.day === lastItem?.close?.day) {
        response += `${response ? ' ' : ''}${DAYS[firstItem?.open?.day as number]}: ${firstOpenTime} - ${lastOpenTime}<br/>`
        return
      }

      response += `${response ? ' ' : ''}${DAYS[firstItem?.open?.day as number]} t/m ${
        DAYS[lastItem?.close?.day as number]
      }: ${firstOpenTime} - ${lastOpenTime}<br/>`
    })

    return response
  } catch (error) {
    console.error(error)
    return ''
  }
}

export function isStoreOpen(periods: GoogleOpeningPeriodItem[]) {
  const day = date(new Date()).get('day')
  const time = Number(`${date(new Date()).format('HHMM')}`)
  let response = false

  periods.forEach((period) => {
    const openTime = (period?.open?.time || 2359) as number
    const closeTime = (period?.close?.time || 0) as number

    if (day === period?.open?.day && time > openTime && time < closeTime) {
      response = true
    }
  })

  return { isOpen: response, day }
}

export default function $page() {
  const {
    page: { contentType, flexContent }
  } = useLoaderData<LoaderData>()

  const transition = useNavigation()

  useEffect(() => {
    if (transition.state === 'loading' || transition.state === 'submitting') {
      progress.start()
    } else {
      progress.done()
    }
  }, [transition.state])

  return (
    <Layout>
      <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex as any} />
    </Layout>
  )
}
