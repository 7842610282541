import { LosseLink, LossePlaatjie, useFetcher, useLoaderData, type LoaderData } from '@ubo/losse-sjedel'
import { forwardRef, useEffect, useRef } from 'react'
import Content from '~/components/elements/Content'
import Loading from '~/components/elements/Loading'
import Quantity from '~/components/elements/Quantity'
import type { Cart as CartType, Page_Flexcontent_Flex_Cart } from '~/graphql/types'
import type { CommerceLoaderData } from '~/services/session.server'
import { currency, getDeliveryInfoData, shippingRateToName, zoomClassesForCertainCategories } from '~/services/utils'
import clsx from 'clsx'
import NoProductsInCart from '~/components/elements/NoProductsInCart'
import { CartBreadcrumbs } from '~/components/elements/Breadcrumbs'
import { NoImage } from '../ProductsWithFilters/Product'
import { variationAttributes } from '~/components/elements/Cart'

export default function Cart({ fields }: { fields: Page_Flexcontent_Flex_Cart }) {
  const { cart } = useLoaderData<LoaderData & CommerceLoaderData>()
  const cartFetcher = useFetcher()
  const formRef = useRef<HTMLFormElement>(null)

  const { groups } = getDeliveryInfoData(cart as unknown as CartType)

  function onChangeQuantity() {
    cartFetcher.submit(formRef.current, {
      replace: false
    })
  }

  useEffect(() => {
    try {
      document.body.classList.remove('!overflow-hidden')
    } catch (error) {}
  }, [])

  const hasItems = groups.length > 0

  const IMAGE_CLASSES = clsx('w-full rounded-xl flex-none product--image--wrapper')

  return (
    <section data-component="Cart" className="container section--bottom">
      <CartBreadcrumbs type="cart" />

      <cartFetcher.Form
        className="max-w-screen-xl xl:px-8 mx-auto grid grid-cols-2 md:grid-cols-3 cart--grid--gap items-start"
        action="/api/cart"
        method="POST"
        id="cart"
        replace={true}
        ref={formRef}
      >
        <div className="col-span-2 flex flex-col cart--grid--gap">
          <input type="hidden" name="_action" value="update" />

          {!hasItems && (
            <div className="p-4 lg:p-6 flex flex-col relative">
              <NoProductsInCart />
            </div>
          )}

          {groups.map((group, i) => {
            return (
              <div
                key={group.title}
                className={clsx(
                  'bg-white rounded-2xl p-4 lg:p-6 flex flex-col relative',
                  group.id === 'same_day_pickup',
                  group.id === 'later_pickup'
                )}
              >
                <span className="text-rc-quilladin opacity-50 font-bold mb-4">
                  {group.id === 'same_day_pickup' ? 'Boulangerie' : 'Bakery & Gelateria'}
                </span>

                <div className="flex flex-col gap-y-6">
                  {group.items.map((item) => {
                    const attributes = variationAttributes(item)
                    return (
                      <div
                        className="grid sm:flex grid-cols-4 gap-3 sm:gap-4 lg:gap-8 max-sm:border-b max-sm:border-rc-litten max-sm:last:border-b-transparent max-sm:pb-3"
                        key={item.key}
                      >
                        <div className="max-sm:w-full max-sm:col-span-1 max-w-full min-w-full sm:max-w-[90px] sm:min-w-[90px] lg:min-w-[125px] lg:max-w-[125px] sm:h-auto h-[74px]">
                          {item.product?.node.image && (
                            <div className={IMAGE_CLASSES}>
                              <LossePlaatjie
                                src={item.product?.node.image}
                                maxwidth={300}
                                className={zoomClassesForCertainCategories(item.product.node)}
                              />
                            </div>
                          )}
                          {!item.product?.node.image && (
                            <div className={IMAGE_CLASSES}>
                              <NoImage categories={item.product?.node.productCategories} />
                            </div>
                          )}
                        </div>

                        <div className="flex flex-col max-sm:col-span-3 sm:pt-1 w-full" aria-label="Product informatie">
                          <LosseLink
                            to={`/product/${item.product?.node.slug}/`}
                            className="max-sm:text-sm lg:text-lg font-bold mb-1 lg:mb-2 hover:underline"
                          >
                            {item.product?.node.name}
                          </LosseLink>
                          {attributes?.map((attribute) => (
                            <div className="text-rc-quilladin opacity-50 text-s sm:text-sm" key={attribute?.key}>
                              <span className="font-bold">{attribute?.key?.replace('-', ' ')}</span>
                              <span>: {attribute?.value}</span>
                            </div>
                          ))}
                        </div>

                        <div className="sm:hidden col-span-1" />

                        <div className="sm:-mt-1 max-sm:col-span-2 max-sm:flex justify-start">
                          <Quantity item={item} onChange={onChangeQuantity} className="max-sm:bg-rc-litten" />
                        </div>

                        <div className="flex sm:flex-col items-center sm:items-end max-sm:col-span-1">
                          <Content className="text-base sm:text-lg lg:text-xl text-rc-sandaconda font-bold w-[80px] lg:w-[100px] text-right">
                            {currency(item.total || '0')}
                          </Content>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>

        <div className="bg-rc-shedinja max-md:col-span-2 rounded-2xl p-4 lg:py-6 lg:px-6 xl:px-10">
          <div className="grid grid-cols-2 gap-y-3 lg:gap-y-4">
            <div className="col-span-1">Subtotaal</div>
            <Content className="text-right col-span-1">{currency(cart.subtotal)}</Content>

            {cart.shippingTotal !== '€&nbsp;0,00' && (
              <>
                <div className="col-span-1 ">Bezorgkosten</div>
                <Content className="text-right col-span-1">
                  <Content>{cart.shippingTotal}</Content>
                  <strong>{cart.chosenShippingMethods && shippingRateToName(cart as unknown as CartType)} </strong>
                </Content>
              </>
            )}

            <div className="col-span-1">BTW</div>
            <Content className="text-right col-span-2 lg:col-span-1">{currency(cart.totalTax)}</Content>

            <div className="cart--total mt-3 lg:mt-4">Totaal</div>
            <Content className="text-right cart--total mt-3 lg:mt-4">{currency(cart.total)}</Content>
          </div>

          <span className="flex items-center justify-center gap-x-2 mb-4 mt-6">
            Veilig betalen met <img src="/static/ideal-white.svg" alt="IDeal Logo" />
          </span>

          <div className="flex flex-col mb-2">
            <button
              className="btn w-full text-center items-center justify-center lg:text-lg lg:py-3"
              type="button"
              onClick={() => {
                if (!formRef.current) return
                const formData = new FormData(formRef.current)
                formData.append('redirect', '/afrekenen/')
                cartFetcher.submit(formData, {
                  method: 'POST',
                  action: '/api/cart'
                })
              }}
              disabled={!hasItems}
            >
              {cartFetcher.state !== 'idle' && <Loading className="text-white mr-2" />}
              <span>Naar afrekenen</span>
            </button>
          </div>

          {(!cart.customDeliveryInfo?.later_time || !cart.customDeliveryInfo?.time) && (
            <span className="text-center text-rc-quilladin/50 text-xs mt-4 block">
              In de volgende stap kan je het afhaal- bezorgmoment bepalen
            </span>
          )}
        </div>
      </cartFetcher.Form>
    </section>
  )
}

const DatepickerInput = forwardRef<HTMLInputElement>(({ ...rest }, ref) => (
  <input {...rest} className="form-duuf-input md:h-[42px] max-lg:text-sm first-letter:capitalize" readOnly ref={ref} />
))
DatepickerInput.displayName = 'Input'
