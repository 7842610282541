import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ProductDetailTwoColumns from './ProductDetailTwoColumns'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    two_columns: ProductDetailTwoColumns
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
