import { Link, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import GlobalAddToCartButton from '~/components/elements/product/GlobalAddToCartButton'
import type { Maybe, Product, SimpleProduct, VariableProduct, WooProductToProductCategoryConnection } from '~/graphql/types'
import { zoomClassesForCertainCategories } from '~/services/utils'

export default function ProductComponent({
  post,
  mobileButtonPlacement = 'left'
}: {
  post: Product
  mobileButtonPlacement?: 'left' | 'right'
}) {
  const simpleProduct = post.woo?.node as SimpleProduct
  const variationProduct = post.woo?.node as VariableProduct

  let product: SimpleProduct | VariableProduct
  if (post.woo?.node.type === 'SIMPLE') {
    product = simpleProduct
  } else {
    product = variationProduct
  }

  const IMAGE_CLASSES = clsx('rounded-lg sm:rounded-2xl w-full product--image--wrapper')

  return (
    <div className="sm:hover:-translate-y-2 transition-all">
      <Link to={product.link || '#'} className="focus:scale-95 transition-all">
        {product.image && (
          <div className={IMAGE_CLASSES}>
            <LossePlaatjie src={product.image} maxwidth={500} className={zoomClassesForCertainCategories(product)} />
          </div>
        )}
        {!product.image && (
          <div className={clsx(IMAGE_CLASSES, 'pb-8')}>
            <NoImage categories={post.woo?.node?.productCategories} />
          </div>
        )}
      </Link>
      <div className="bg-white rounded-lg sm:mx-3 lg:mx-4 p-3 sm:p-4 lg:p-6 -mt-2 lg:-mt-4 z-10 relative flex flex-col">
        <span className="font-bold lg:text-lg xl:text-xl mb-4 lg:mb-6">{product.name}</span>

        <div className={clsx('flex', mobileButtonPlacement === 'right' && 'max-sm:justify-end')}>
          <GlobalAddToCartButton product={product} />
        </div>
      </div>
    </div>
  )
}

export function NoImage({ categories }: { categories?: Maybe<WooProductToProductCategoryConnection> | undefined }) {
  const category = categories?.nodes?.find((node) => !!node.parent)

  if (!category) return null

  const IMAGE_URLS: { [key: string]: string | Record<string, string> } = {
    bakery: {
      default: '/static/types/vers-brood.svg',
      gebak: '/static/types/vers-gebak.svg',
      'koek-en-chocola': '/static/types/vers-gebak.svg'
    },
    gelateria: '/static/types/schepijs.svg',
    boulangerie: {
      default: '/static/types/belegde-broodjes.svg',
      'warme-dranken': '/static/types/koffie.svg',
      'koude-dranken': '/static/types/koffie.svg'
    }
  }

  let image_url = IMAGE_URLS[category.parent?.node?.slug as string]
  if (!image_url) return null
  if (typeof image_url === 'object') {
    image_url = image_url[category.slug as string] || image_url.default
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      <img src={image_url} className="w-[40%] h-[40%] object-contain" alt="" />
    </div>
  )
}
