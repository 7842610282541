import clsx from 'clsx'

export default function NoProductsInCart({ className = '' }) {
  return (
    <div className={clsx('p-10', className)}>
      <div className="text-center content py-6 mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          className="text-rc-quilladin mx-auto"
        >
          <g fill="currentColor" clipPath="url(#clip0_1329_2587)">
            <path d="M24 3H4.242L4.2 2.649A3 3 0 001.222 0H0v2h1.222a1 1 0 01.993.883L3.8 16.351A3 3 0 006.778 19H20v-2H6.778a1 1 0 01-.993-.884L5.654 15h16.182L24 3zm-3.836 10H5.419l-.941-8h17.129l-1.443 8zM7 24a2 2 0 100-4 2 2 0 000 4zM17 24a2 2 0 100-4 2 2 0 000 4z"></path>
          </g>
        </svg>
        <span className="mt-2 block">Nog geen producten in de winkelmand</span>
      </div>
    </div>
  )
}
