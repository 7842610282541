import { useFetcher, type LoaderData, LosseLink, Link, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment, useState } from 'react'
import Content from '~/components/elements/Content'
import Allergens from '~/components/elements/product/Allergens'
import GlobalAddToCartButton, { AddToCartButton } from '~/components/elements/product/GlobalAddToCartButton'
import VariationSelect from '~/components/elements/product/VariationSelect'
import type { Page_Flexcontent_Flex_ProductDetail, Product, ProductTagConnection, SimpleProduct, VariableProduct } from '~/graphql/types'
import useCartMessage from '~/services/useCartMessage'
import { currency, useVariations } from '~/services/utils'
import { NoImage } from '../ProductsWithFilters/Product'

export default function ProductDetailTwoColumns({ fields }: { fields: Page_Flexcontent_Flex_ProductDetail }) {
  const { page } = useLoaderData<LoaderData>()
  const post = page as unknown as Product & { productTags: ProductTagConnection }
  if (!post.woo) return null
  const product = post.woo?.node
  const crossSells = (product as SimpleProduct).crossSell

  return (
    <div className="container">
      <div className="breadcrumbs flex items-center flex-wrap gap-x-4 mb-6 lg:mb-8 max-sm:text-sm">
        {product.productCategories?.nodes?.map((category) => (
          <Fragment key={category.id}>
            <Link
              className="hover:underline transition-all"
              to={category.parentDatabaseId ? `/${category.parent?.node.slug}/?categoryId=${category.databaseId}` : `/${category.slug}/`}
            >
              {category.name}
            </Link>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" fill="none" viewBox="0 0 7 11">
              <path
                fill="#370709"
                d="M1.559 10.81L.442 9.69 4.629 5.5.442 1.31 1.562.19l4.184 4.19a1.583 1.583 0 010 2.24l-4.187 4.19z"
              ></path>
            </svg>
          </Fragment>
        ))}
        <Content className="font-bold">{post.title}</Content>
      </div>

      <div className="max-w-screen-xl xl:px-8 mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-2 gap-6 lg:gap-8">
          <div className="md:-mr-10 col-span-1">
            {post.featuredImage?.node && (
              <LossePlaatjie
                className="w-full md:h-full max-md:aspect-[16/10] rounded-2xl object-cover"
                src={post.featuredImage?.node}
                maxwidth={900}
                autoheight={false}
              />
            )}
            {!post.featuredImage?.node && (
              <div className="w-full md:h-full max-md:aspect-[16/10] rounded-2xl object-cover bg-rc-quilladin">
                <NoImage categories={post.woo?.node?.productCategories} />
              </div>
            )}
          </div>
          <div className="bg-white col-span-1 sm:col-span-2 md:col-span-1 p-4 lg:p-8 rounded-2xl md:ml-10 flex flex-col justify-between">
            <div>
              <div className="content mb-4">
                <h1 className="sub--title">{post.title}</h1>
              </div>
              <Content className="content mb-6">{fields.description}</Content>

              {product.type === 'SIMPLE' && <SimpleAddToCart product={product} />}
              {product.type === 'VARIABLE' && <VariationAddToCart product={product as VariableProduct} />}
            </div>

            <Allergens withTitle tags={post.productTags} />
          </div>
        </div>

        {crossSells && crossSells?.nodes?.length > 0 && (
          <div aria-label="Cross sells" className="py-20">
            <div className="content childre-h2:text-xl md:children-h2:text-2xl mb-8">
              <h2>Lekker voor erbij</h2>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              {crossSells?.nodes?.map((post) => {
                const product = post as SimpleProduct

                return (
                  <div className="bg-white flex rounded-xl hover:-translate-y-2 transition-all" key={product.databaseId}>
                    <LosseLink to={post.link}>
                      <LossePlaatjie
                        className="min-w-[125px] max-w-[125px] xl:max-w-[160px] xl:min-w-[160px] flex-none h-full object-cover rounded-xl"
                        maxwidth={300}
                        src={product.image}
                      />
                    </LosseLink>

                    <div className="px-4 xl:px-6 py-4 flex flex-col justify-between">
                      <h3 className="text-lg lg:text-xl font-bold mb-4 xl:mb-6">{product.name}</h3>

                      <GlobalAddToCartButton product={product} showText size="normal" />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function SimpleAddToCart({ product }: { product: SimpleProduct }) {
  const hasOptions = product.attributes?.nodes?.find((attribute) => attribute?.visible)

  return (
    <div>
      <div className="flex items-center gap-x-10">
        {product.stockStatus === 'IN_STOCK' ? (
          <>{hasOptions ? <SimpleWithOptionsAddToCart product={product} /> : <SimpleWithoutOptionsAddToCart product={product} />}</>
        ) : (
          <>
            <span
              className="text-lg md:text-xl xl:text-2xl font-bold"
              dangerouslySetInnerHTML={{
                __html: currency(product.regularPrice || '0', product.onSale ? product.salePrice || undefined : undefined)
              }}
            />
            <div className="flex justify-end">
              {product.stockStatus === 'OUT_OF_STOCK' && <span className="form-duuf-error">Uitverkocht</span>}
              {product.stockStatus === 'ON_BACKORDER' && <span className="form-duuf-error">Nabestelling</span>}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

function SimpleWithoutOptionsAddToCart({ product }: { product: SimpleProduct }) {
  const cartFetcher = useFetcher()
  const [isCartMessageAvailable] = useCartMessage(cartFetcher)

  const [showExtraNote, setShowExtraNote] = useState(false)
  const [extraNote, setExtraNote] = useState<string | null>(null)

  const extraData: Record<string, string> = {}

  if (showExtraNote && extraNote) {
    extraData['Specifieke wensen'] = extraNote
  }

  return (
    <cartFetcher.Form method="post" action="/api/cart" className="w-full">
      <input type="hidden" name="_action" value="add" />
      <input type="hidden" name="productId" value={product.databaseId} />
      <input type="hidden" name="extraData" value={JSON.stringify(extraData)} />

      <div className="mt-6">
        <button
          type="button"
          className="font-bold flex items-center gap-x-2 mb-4 btn--dark"
          onClick={() => setShowExtraNote(!showExtraNote)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="feather feather-message-circle"
            viewBox="0 0 24 24"
          >
            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
          </svg>
          Specifieke wensen?
        </button>

        {showExtraNote && (
          <input
            type="text"
            name="extraNote"
            placeholder="Bijvoorbeeld: zonder tomaat"
            className="w-full border border-black/10 rounded-lg p-2 mb-4"
            onChange={(e) => setExtraNote(e.target.value)}
          />
        )}
      </div>

      <div className="flex items-center">
        <span
          className="text-lg md:text-xl xl:text-2xl font-bold mr-4"
          dangerouslySetInnerHTML={{
            __html: currency(product.regularPrice || '0', product.onSale ? product.salePrice || undefined : undefined)
          }}
        />
        <AddToCartButton cartFetcher={cartFetcher} showMessage={isCartMessageAvailable} text="Voeg toe" />
      </div>
    </cartFetcher.Form>
  )
}

function SimpleWithOptionsAddToCart({ product }: { product: SimpleProduct }) {
  const cartFetcher = useFetcher()
  const { variation, fields, setOptions, options } = useVariations(product)

  const [isCartMessageAvailable] = useCartMessage(cartFetcher)

  const [showExtraNote, setShowExtraNote] = useState(false)
  const [extraNote, setExtraNote] = useState<string | null>(null)

  const extraData: Record<string, string> = Object.values(options).reduce((acc, option) => ({ ...acc, [option.group]: option.value }), {})

  if (showExtraNote && extraNote) {
    extraData['Specifieke wensen'] = extraNote
  }

  return (
    <cartFetcher.Form method="post" action="/api/cart" className="w-full">
      <input type="hidden" name="_action" value="add" />
      <input type="hidden" name="productId" value={product.databaseId} />
      {product.databaseId !== variation?.databaseId && <input type="hidden" name="variationId" value={variation?.databaseId} />}
      {product.databaseId === variation?.databaseId && <input type="hidden" name="extraData" value={JSON.stringify(extraData)} />}

      <div className="mt-6">
        <button
          type="button"
          className="font-bold flex items-center gap-x-2 mb-4 btn--dark"
          onClick={() => setShowExtraNote(!showExtraNote)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="feather feather-message-circle"
            viewBox="0 0 24 24"
          >
            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
          </svg>
          Specifieke wensen?
        </button>

        {showExtraNote && (
          <input
            type="text"
            name="extraNote"
            placeholder="Bijvoorbeeld: zonder tomaat"
            className="w-full border border-black/10 rounded-lg p-2 mb-4"
            onChange={(e) => setExtraNote(e.target.value)}
          />
        )}
      </div>

      <div className="flex gap-x-4 flex-wrap gap-y-2 lg:gap-y-4">
        {Object.entries(fields).map(([name, value]) => (
          <VariationSelect
            key={name}
            selected={options[name]}
            setSelected={(option) => {
              setOptions({
                ...options,
                [name]: option
              })
            }}
            options={value.options}
          />
        ))}
      </div>

      <div className="grid items-end mt-10">
        {/* <Allergens product={product} /> */}
        <div className="flex items-center">
          <AnimatePresence>
            {isCartMessageAvailable && !cartFetcher.data.success && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="text-right pr-4 form-duuf-error"
              >
                Kon product niet toevoegen aan winkelmand
              </motion.div>
            )}
          </AnimatePresence>

          {variation ? (
            <div className="flex items-center">
              <div
                className="text-lg md:text-xl xl:text-2xl font-bold mr-4"
                dangerouslySetInnerHTML={{
                  __html: currency(variation?.regularPrice || '', variation?.onSale ? variation?.salePrice || undefined : undefined)
                }}
              />
              <AddToCartButton cartFetcher={cartFetcher} showMessage={isCartMessageAvailable} text="Voeg toe" />
            </div>
          ) : (
            <div className="flex justify-end">
              <button type="button" className="ml-auto text-sm text-left">
                {Object.values(options).some((item) => item.attributeId === 0)
                  ? 'Kies tussen de opties om door te gaan'
                  : 'Deze combinatie is niet beschikbaar'}
              </button>
            </div>
          )}
        </div>
      </div>
    </cartFetcher.Form>
  )
}

function VariationAddToCart({ product }: { product: VariableProduct }) {
  const { variation, options, setOptions, fields } = useVariations(product)
  const cartFetcher = useFetcher()
  const [isCartMessageAvailable] = useCartMessage(cartFetcher)

  return (
    <cartFetcher.Form method="post" action="/api/cart">
      <input type="hidden" name="_action" value="add" />
      <input type="hidden" name="productId" value={product.databaseId} />
      <input type="hidden" name="variationId" value={variation?.databaseId} />

      <div className="md:flex gap-2 lg:gap-3 xl:gap-4 grid grid-cols-2 flex-wrap mb-6 lg:mb-10">
        {Object.entries(fields).map(([name, value]) => (
          <div key={name}>
            <VariationSelect
              selected={options[name]}
              setSelected={(option) => {
                setOptions({
                  ...options,
                  [name]: option
                })
              }}
              options={value.options}
            />
          </div>
        ))}
      </div>

      <div className="flex items-center gap-6 xl:gap-10">
        <span
          className={clsx('text-lg md:text-xl xl:text-2xl font-bold', !variation && 'w-[200px]')}
          dangerouslySetInnerHTML={{ __html: currency(variation?.price || product.price || '0') }}
        />

        {variation ? (
          <AddToCartButton cartFetcher={cartFetcher} showMessage={isCartMessageAvailable} text="Voeg toe" size="big" />
        ) : (
          <div className="flex lg:justify-end">
            {Object.values(options).some((item) => item.attributeId === 0) ? (
              <div className="max-lg:mt-2 lg:ml-auto text-sm italic">Kies tussen de opties om door te gaan</div>
            ) : (
              <div className="max-lg:mt-2 lg:ml-auto form-duuf-error">Deze combinatie is niet beschikbaar</div>
            )}
          </div>
        )}
      </div>

      <AnimatePresence>
        {isCartMessageAvailable && !cartFetcher.data.success && (
          <motion.div initial={{ opacity: 0, y: 5 }} animate={{ opacity: 1, y: 12 }} exit={{ opacity: 0, y: 5 }}>
            <span className="form-duuf-error">Kon product niet toevoegen aan winkelmand</span>
          </motion.div>
        )}
      </AnimatePresence>
    </cartFetcher.Form>
  )
}
