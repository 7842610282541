import { motion } from 'framer-motion'
import type { Maybe, Component_Header_MenuHeader_Submenu, Acf_Link } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

export default function HeaderSubmenu({
  fields,
  link
}: {
  fields: Maybe<Component_Header_MenuHeader_Submenu> | null
  link: Maybe<Acf_Link> | null
}) {
  if (!fields) return null

  return (
    <motion.ul
      className="max-lg:hidden absolute top-full -left-[150px] overflow-hidden bg-rc-quilladin rounded-2xl shadow w-[850px]"
      initial={{ opacity: 0, y: -5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -5 }}
    >
      <div className="flex gap-8 p-10 text-white">
        <div className="w-[60%]">
          <div className="flex items-center">
            <LossePlaatjie src={fields.icon} alt={fields.title || ''} className="w-[100px] h-[60px] object-contain" maxwidth={300} />
            <LosseLink to={link?.url} className="ml-6 text-3xl font-rc-plus font-bold hover:underline">
              {fields.title}
            </LosseLink>
          </div>
          <div className="mt-10 -ml-4 grid grid-cols-2">
            {fields.category
              ?.sort((a, b) => (a?.menuOrder || 1) - (b?.menuOrder || 1))
              .map((item) => (
                <div key={item?.databaseId} className="py-2 col-span-1">
                  <LosseLink
                    to={`${link?.url}?categoryId=${item?.databaseId}`}
                    className="font-rc-plus btn--pseudo px-4 py-2 rounded-full hover:bg-rc-shedinja"
                  >
                    {item?.name}
                  </LosseLink>
                </div>
              ))}
          </div>
        </div>
        <div className="w-[40%]">
          <LossePlaatjie src={fields.image} alt="" className="w-full h-full object-cover rounded-2xl overflow-hidden" maxwidth={500} />
        </div>
      </div>
    </motion.ul>
  )
}
