import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import type { CartItem } from '~/graphql/types'

export default function Quantity({ item, onChange, className }: { item: CartItem; onChange: () => void; className?: string }) {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (item.quantity && ref.current) ref.current.value = item.quantity.toString()
  }, [item])

  return (
    <div className={clsx('flex items-center gap-x-1 lg:gap-x-2 rounded-full sm:p-1', className)}>
      <button
        onClick={() => {
          ref.current?.stepDown()
          onChange()
        }}
        type="button"
        className="btn--pseudo btn--base p-1 hover:bg-rc-shedinja"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
        </svg>
      </button>

      <input
        className="w-6 text-center webkit--appearance--none bg-inherit font-rc-plus font-bold text-sm"
        type="number"
        min={0}
        name={`cart[${item.key}][quantity]`}
        defaultValue={item.quantity || 1}
        ref={ref}
        onInput={onChange}
      />

      <button
        type="button"
        onClick={() => {
          ref.current?.stepUp()
          onChange()
        }}
        className="btn--pseudo btn--base p-1 hover:bg-rc-shedinja"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
        </svg>
      </button>
    </div>
  )
}
