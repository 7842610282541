import Slider, { Slide } from '~/components/elements/Slider'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { EffectCube } from 'swiper'
import clsx from 'clsx'

export default function ContentImages({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section
      data-component="ContentImages"
      className={clsx('relative', fields.spaceTop ? 'section' : 'section--bottom', fields.bgEndGreen && 'bg-rc-sandaconda')}
    >
      <div
        className={clsx(
          'absolute z-0 pointer-events-none inset-x-0 top-0',
          fields.bgEndGreen ? 'bg-rc-litten h-[55%] md:h-[65%]' : 'bg-rc-sandaconda h-[65%] md:h-[75%]'
        )}
      />
      <div className="container relative z-10">
        {fields.description && <Content className="content text-white mb-12">{fields?.description}</Content>}

        <div className="max-w-screen-lg mx-auto">
          <Slider
            modules={[EffectCube]}
            effect="cube"
            cubeEffect={{
              shadow: false,
              slideShadows: false
            }}
            autoplay={{
              delay: 5000
            }}
            loop
            slidesPerView={1}
            className="overflow-hidden relative"
          >
            {fields?.images?.map((item, index) => (
              <Slide key={index} className="aspect-video">
                <LossePlaatjie
                  src={item}
                  className="w-full h-full object-cover rounded-xl lg:rounded-2xl"
                  maxwidth={1280}
                  autoheight={false}
                />
              </Slide>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}
